<template>
  <div>
    <v-app-bar app color="orange" dark flat class="darken-3">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        v-if="mobile && $store.state.isLoggedin"
      ></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-avatar size="60" class="mr-5 ml-4 mt-2">
          <v-img
            height="50px"
            width="30"
            contain
            src="../assets/logo.png"
          ></v-img>
        </v-avatar>
        <v-toolbar-title class="mt-2"> Delivery Portal</v-toolbar-title>
      </div>

      <v-spacer></v-spacer>

      <v-btn
        @click="
          $store.dispatch('loginAdmin', false);
          $router.push({ name: 'login' });
        "
        text
        v-if="$store.state.isLoggedin"
      >
        <span class="text-capitalize mr-2">logout</span>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      width="auto"
      v-if="mobile && $store.state.isLoggedin"
      app
      v-model="drawer"
      class="pt-16 pl-1 orange darken-3"
    >
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item
            class="white--text"
            @click="
              $router.push({
                name: 'home',
              })
            "
          >
            <v-list-item-title
              ><v-icon class="mr-2 grey--text text--lighten-3">home</v-icon>
              <span style="font-size: 17px">Home</span></v-list-item-title
            >
          </v-list-item>
          <v-list-item
            class="white--text"
            @click="
              $router.push({
                name: 'orders',
              })
            "
          >
            <v-list-item-title
              ><v-icon class="mr-2 grey--text text--lighten-3"
                >shopping_cart</v-icon
              >
              <span style="font-size: 17px">Orders</span></v-list-item-title
            >
          </v-list-item>

          <v-list-item class="white--text">
            <v-list-item-title
              ><v-icon class="mr-2 grey--text text--lighten-3"
                >local_shipping
              </v-icon>
              <span style="font-size: 17px">Drivers</span></v-list-item-title
            >
          </v-list-item>

          <v-list-item class="white--text">
            <v-list-item-title
              ><v-icon class="mr-2 grey--text text--lighten-3">group </v-icon>
              <span style="font-size: 17px">Users</span></v-list-item-title
            >
          </v-list-item>

          <v-list-item class="white--text"      @click="
              $router.push({
                name: 'university',
              })
            ">
            <v-list-item-title
              ><v-icon class="mr-2 grey--text text--lighten-3">school </v-icon>
              <span style="font-size: 17px"
                >Universities</span
              ></v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer
      width="160"
      v-if="!mobile && $store.state.isLoggedin"
      permanent
      app
      v-model="drawer1"
      class="pt-16 pl-1 orange darken-3"
    >
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item
            class="white--text"
            @click="
              $router.push({
                name: 'home',
              })
            "
          >
            <v-list-item-title
              ><v-icon class="mr-2 grey--text text--lighten-3">home</v-icon>
              <span style="font-size: 17px">Home</span></v-list-item-title
            >
          </v-list-item>
          <v-list-item
            class="white--text"
            @click="
              $router.push({
                name: 'orders',
              })
            "
          >
            <v-list-item-title
              ><v-icon class="mr-2 grey--text text--lighten-3"
                >shopping_cart</v-icon
              >
              <span style="font-size: 17px">Orders</span></v-list-item-title
            >
          </v-list-item>

          <v-list-item class="white--text">
            <v-list-item-title
              ><v-icon class="mr-2 grey--text text--lighten-3"
                >local_shipping
              </v-icon>
              <span style="font-size: 17px">Drivers</span></v-list-item-title
            >
          </v-list-item>

          <v-list-item class="white--text">
            <v-list-item-title
              ><v-icon class="mr-2 grey--text text--lighten-3">group </v-icon>
              <span style="font-size: 17px">Users</span></v-list-item-title
            >
          </v-list-item>

          <v-list-item class="white--text" @click="
              $router.push({
                name: 'university',
              })
            ">
            <v-list-item-title
              ><v-icon class="mr-2 grey--text text--lighten-3">school </v-icon>
              <span style="font-size: 17px"
                >Universities</span
              ></v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      drawer1: false,
      group: null,
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>