import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../components/login'
import home from '../components/home'
import orders from '../components/orders'
import university from '../components/universities'


Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'login',
    component: login
  },

  {
    path: '/home',
    name: 'home',
    component: home
  },

  {
    path: '/orders',
    name: 'orders',
    component: orders
  },

  {
    path: '/university',
    name: 'university',
    component: university
  },

]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

export default router
