<template>
  <div class="container">
    <div class="text-center mt-16" v-if="pageLoading">
      <v-progress-circular
        :size="70"
        :width="2"
        color="grey"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-if="!pageLoading">
      <v-layout row wrap class="" justify-space-around>
        <v-flex xs12 md4 class="pa-2" id="">
          <v-card class="pa-2 ma-1">
       
             <v-card-title
              >University <v-spacer></v-spacer>
              <v-btn
                @click="addUniversityPopUp = true"
                small
                dark
                text
                class="orange darken-4"
              >
                <span class="text-capitalize">Add</span>
              </v-btn></v-card-title>

            <v-layout
              row
              wrap
              v-for="university in allUniversities"
              :key="university.university_id"
              class="ma-1"
            >
              <v-flex xs12>
                <v-divider></v-divider>
              </v-flex>
              <v-flex xs12b class="mt-2">
                <a @click="getLounge(university.university_id)">
                  <v-card class="pa-1" elevation="0">
                    <span class="">{{ university.university_name }}</span
                    ><br /><span style="font-size: 13px" class="grey--text">{{
                      university.campus
                    }}</span>
                  </v-card>
                </a>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>

        <v-flex xs12 md4 class="pa-2" id="">
          <v-card class="pa-2 ma-1" v-if="universityID != ''">

               <v-card-title
              >Lounges and Restaurants <v-spacer></v-spacer>
              <v-btn
                @click="loungeAdderPopUp = true"
                small
                dark
                text
                class="orange darken-4"
              >
                <span class="text-capitalize">Add</span>
              </v-btn></v-card-title
            >
            <v-layout
              row
              wrap
              v-for="lounge in allLounge.getLounge"
              :key="lounge.lounge_id"
              class="ma-1"
            >
              <v-flex xs12>
                <v-divider></v-divider>
              </v-flex>
              <v-flex xs12b class="mt-2">
                <a @click="getFoodsAndDrinks(lounge.lounge_id)">
                  <v-card class="pa-1" elevation="0">
                    <span class="">{{ lounge.lounge_name }}</span
                    ><br /><span style="font-size: 13px" class="grey--text">{{
                      lounge.lounge_phone
                    }}</span>
                  </v-card>
                </a>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>

        <v-flex xs12 md4 class="pa-2" id="">
          <div class="text-center mt-16" v-if="foodLoading">
            <v-progress-circular
              :size="70"
              :width="2"
              color="grey"
              indeterminate
            ></v-progress-circular>
          </div>
          <v-card class="pa-2 ma-1" v-if="!foodLoading && loungeId != ''">
            <v-card-title
              >Foods and Drinks <v-spacer></v-spacer>
              <v-btn
                @click="foodAdderPopUp = true"
                small
                dark
                text
                class="orange darken-4"
              >
                <span class="text-capitalize">Add</span>
              </v-btn></v-card-title
            >
            <v-layout row wrap class="ma-1" justify-space-around>
              <v-flex
                xs6
                class="mt-2 pa-1"
                v-for="food in foodsAndDrinks"
                :key="food.food_and_drink_id"
              >
                <a>
                  <v-card class="pa-1" elevation="0">
                    <span class="">{{ food.food_and_drink_name }}</span
                    ><br /><span style="font-size: 13px" class="grey--text"
                      >{{ food.food_and_drink_price }} Birr</span
                    >
                  </v-card>
                </a>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>

      <v-dialog v-model="foodAdderPopUp" persistent max-width="400px">
        <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Dialog
        </v-btn>
      </template> -->
        <v-card>
          <v-card-title>
            <span class="">Food Adder</span>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="foodAdderPopUp = false">
              <span class="text-capitalize"> Close</span>
            </v-btn>
          </v-card-title>
          <v-card-text class="pl-10 pr-10">
            <v-row class="text-center" id="login">
              <v-col cols="12" class="mt-5">
                <p class="">Enter the following required fields</p>
              </v-col>

              <v-flex xs12 class="text-center">
                <input
                  type="file"
                  accept="image/*"
                  ref="selectImage"
                  style="display: none"
                  @change="OnInputFile"
                />
                <input
                  type="file"
                  accept="image/*"
                  ref="changeImage"
                  style="display: none"
                  @change="OnInputFile"
                />

                <img
                  @mouseenter="changeCursor"
                  id="imageCamera"
                  :src="image1"
                  style="border-radius: 5px"
                  height="120px"
                
                  contain
                  v-if="image1 != ''"
                  @click="changeImage()"
                />
                <br v-if="image1 != ''" />

                <div
                  @mouseenter="changeCursor"
                  id="imageCamera"
                  class="ml-5 mr-5 text-center grey lighten-3"
                  v-if="image1 == ''"
                  @click="simulateInputfileClicked"
                  style="border-radius: 5px"
                >
                  <!-- <v-btn centered outlined large fab class="ma-4"> -->
                  <v-icon centered class="mt-10 grey--text">camera_alt</v-icon>
                  <br />
                  <br />
                  <br />
                  <p class="text-center grey--text">Select Image</p>
                  <!-- </v-btn> -->
                </div>

                <br />
              </v-flex>
              <v-flex xs12 class="mt-1">
                <v-text-field
                  style="font-size: 11px"
                  v-model="foodOrDrinkName"
                  :rules="[(v) => !!v || 'Required']"
                  label="Food or Drink name"
                  required
                  dense
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  style="font-size: 11px"
                  v-model="price"
                  :rules="[(v) => !!v || 'Required']"
                  label="Price"
                  required
                  dense
                  outlined
                  type="number"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  style="font-size: 11px"
                  v-model="foilPrice"
                  :rules="[(v) => !!v || 'Required']"
                  label="Foil Price"
                  required
                  dense
                  outlined
                  type="number"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  style="font-size: 11px"
                  v-model="description"
                  :rules="[(v) => !!v || 'Required']"
                  label="Description"
                  required
                  dense
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-btn
                  :loading="addLoading"
                  dark
                  small
                  text
                  class="orange darken-4 pl-10 pr-10 mb-10"
                  @click="addFoodAndDrink"
                >
                  <span class="text-capitalize">Add</span>
                </v-btn>
              </v-flex>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

        <v-dialog v-model="addUniversityPopUp" persistent max-width="400px">
        <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Dialog
        </v-btn>
      </template> -->
        <v-card>
          <v-card-title>
            <span class="">University Adder</span>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="addUniversityPopUp = false">
              <span class="text-capitalize"> Close</span>
            </v-btn>
          </v-card-title>
          <v-card-text class="pl-10 pr-10">
            <v-row class="text-center" id="login">
              <v-col cols="12" class="mt-5">
                <p class="">Enter the following required fields</p>
              </v-col>

              <v-flex xs12 class="text-center">
                <input
                  type="file"
                  accept="image/*"
                  ref="selectImage"
                  style="display: none"
                  @change="OnInputFile"
                />
                <input
                  type="file"
                  accept="image/*"
                  ref="changeImage"
                  style="display: none"
                  @change="OnInputFile"
                />

                <img
                  @mouseenter="changeCursor"
                  id="imageCamera"
                  :src="image1"
                  style="border-radius: 5px"
                  height="120px"
                  
                  contain
                  v-if="image1 != ''"
                  @click="changeImage()"
                />
                <br v-if="image1 != ''" />

                <div
                  @mouseenter="changeCursor"
                  id="imageCamera"
                  class="ml-5 mr-5 text-center grey lighten-3"
                  v-if="image1 == ''"
                  @click="simulateInputfileClicked"
                  style="border-radius: 5px"
                >
                  <!-- <v-btn centered outlined large fab class="ma-4"> -->
                  <v-icon centered class="mt-10 grey--text">camera_alt</v-icon>
                  <br />
                  <br />
                  <br />
                  <p class="text-center grey--text">Select Logo</p>
                  <!-- </v-btn> -->
                </div>

                <br />
              </v-flex>
              <v-flex xs12 class="mt-1">
                <v-text-field
                  style="font-size: 11px"
                  v-model="universityName"
                  :rules="[(v) => !!v || 'Required']"
                  label="University name"
                  required
                  dense
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  style="font-size: 11px"
                  v-model="campus"
                  :rules="[(v) => !!v || 'Required']"
                  label="Campus"
                  required
                  dense
                  outlined
                  
                ></v-text-field>
              </v-flex>           

              <v-flex xs12>
                <v-text-field
                  style="font-size: 11px"
                  v-model="coordinate"
                  :rules="[(v) => !!v || 'Required']"
                  label="Coordinate"
                  required
                  dense
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-btn
                  :loading="addLoading"
                  dark
                  small
                  text
                  class="orange darken-4 pl-10 pr-10 mb-10"
                  @click="addUniversity"
                >
                  <span class="text-capitalize">Add</span>
                </v-btn>
              </v-flex>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>


        <v-dialog v-model="loungeAdderPopUp" persistent max-width="400px">
        <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Dialog
        </v-btn>
      </template> -->
        <v-card>
          <v-card-title>
            <span class="">Lounge Adder</span>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="loungeAdderPopUp = false">
              <span class="text-capitalize"> Close</span>
            </v-btn>
          </v-card-title>
          <v-card-text class="pl-10 pr-10">
            <v-row class="text-center" id="login">
              <v-col cols="12" class="mt-5">
                <p class="">Enter the following required fields</p>
              </v-col>

              <v-flex xs12 class="text-center">
                <input
                  type="file"
                  accept="image/*"
                  ref="selectImage"
                  style="display: none"
                  @change="OnInputFile"
                />
                <input
                  type="file"
                  accept="image/*"
                  ref="changeImage"
                  style="display: none"
                  @change="OnInputFile"
                />

                <img
                  @mouseenter="changeCursor"
                  id="imageCamera"
                  :src="image1"
                  style="border-radius: 5px"
                  height="120px"
                  
                  contain
                  v-if="image1 != ''"
                  @click="changeImage()"
                />
                <br v-if="image1 != ''" />

                <div
                  @mouseenter="changeCursor"
                  id="imageCamera"
                  class="ml-5 mr-5 text-center grey lighten-3"
                  v-if="image1 == ''"
                  @click="simulateInputfileClicked"
                  style="border-radius: 5px"
                >
                  <!-- <v-btn centered outlined large fab class="ma-4"> -->
                  <v-icon centered class="mt-10 grey--text">camera_alt</v-icon>
                  <br />
                  <br />
                  <br />
                  <p class="text-center grey--text">Select Image</p>
                  <!-- </v-btn> -->
                </div>

                <br />
              </v-flex>
              <v-flex xs12 class="mt-1">
                <v-text-field
                  style="font-size: 11px"
                  v-model="loungeName"
                  :rules="[(v) => !!v || 'Required']"
                  label="Lounge name"
                  required
                  dense
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  style="font-size: 11px"
                  v-model="loungePhone"
                  :rules="[(v) => !!v || 'Required']"
                  label="Lounge phone"
                  required
                  dense
                  outlined
                  
                ></v-text-field>
              </v-flex>           

              <v-flex xs12>
                <v-text-field
                  style="font-size: 11px"
                  v-model="category"
                  :rules="[(v) => !!v || 'Required']"
                  label="Category"
                  required
                  dense
                  outlined
                ></v-text-field>
              </v-flex>

                 <v-flex xs12>
                <v-text-field
                  style="font-size: 11px"
                  v-model="durationInMinutes"
                  :rules="[(v) => !!v || 'Required']"
                  label="Minutes"
                  required
                  dense
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-btn
                  :loading="addLoading"
                  dark
                  small
                  text
                  class="orange darken-4 pl-10 pr-10 mb-10"
                  @click="addLounge"
                >
                  <span class="text-capitalize">Add</span>
                </v-btn>
              </v-flex>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import firebase from "firebase";
import * as blurhash from "blurhash";
export default {
  data() {
    return {
      allUniversities: [],
      allLounge: "",
      foodsAndDrinks: [],
      pageLoading: false,
      foodLoading: false,
      loungeId: "",
      universityID:"",
      foodAdderPopUp: false,
      addUniversityPopUp:false,
      loungeAdderPopUp:false,

      foodOrDrinkName: "",
      price: "",
      foilPrice: 0,
      description: "",

      universityName:"",
      campus:"",
      coordinate:"",

      loungeName:"",
      loungePhone:"",
      category:"",
      durationInMinutes:"",

      files: [],
      imageUrl1: "",
      image1: "",
      blurhash1: "",
      addLoading: false,
    };
  },
  methods: {
    OnInputFile(e) {
      const files = e.target.files;
      this.files = [];
      Array.from(files).forEach((file) => this.addImage(file));
    },
    changeImage() {
      this.$refs.changeImage.click();
    },
    changeCursor(e) {
      e.target.style.cursor = "pointer";
    },

    async uploadImage() {
      var storageReference = firebase
        .storage()
        .ref("abimaadmin/image" + Math.random() + ".png");

      await (await storageReference.put(this.files[0])).ref
        .getDownloadURL()
        .then((url) => {
          this.imageUrl1 = url;
        });
    },

    async decodeImagetoBlurhash(url) {
      const loadImage = async (src) =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve(img);
          img.onerror = (...args) => reject(args);
          img.src = src;
          img.crossOrigin = "";
        });

      const getImageData = (image) => {
        const canvas = document.createElement("canvas");
        canvas.width = image.width;

        canvas.height = image.height;

        const context = canvas.getContext("2d");

        context.drawImage(image, 0, 0);

        return context.getImageData(0, 0, image.width, image.height);
      };

      const image = await loadImage(url);
      const imageData = getImageData(image);

      return blurhash.encode(
        imageData.data,
        imageData.width,
        imageData.height,
        4,
        3
      );
    },

    addImage(file) {
      if (!file.type.match("image.*")) {
        return;
      }
      this.files.push(file);
      const reader = new FileReader();
      reader.onload = (f) => {
        this.image1 = f.target.result;
      };
      reader.readAsDataURL(file);
    },

    simulateInputfileClicked() {
      this.$refs.selectImage.click();
    },

    // addFoodPopUp() {
    //   this.foodAdderPopUp = true;
    // },
    getLounge(universityId) {
      this.universityID=universityId

      
      this.loungeId = "";
      this.allLounge = "";
      this.foodsAndDrinks = [];
      for (let i = 0; i < this.allUniversities.length; i++) {
        if (this.allUniversities[i].getLounge.length > 0)
          if (
            universityId == this.allUniversities[i].getLounge[0].university_id
          ) {
            this.allLounge = this.allUniversities[i];
          }
      }
    },
    async getUniversity() {
      this.pageLoading = true;
      try {
        var universityResult = await axios({
          method: "POST",
          url: this.$store.state.deliveryApi,
          data: {
            query: `{
                 getAllUniversities{
                    university_id
                    university_name
                    university_coordinate
                    campus
                    logo
                    getLounge{
                      lounge_id 
                      university_id
                      lounge_name
                      lounge_phone
                      lounge_image
                      category
                      minutes
      
                    }
                  }


                      }
            `,
          },
        });

        this.allUniversities = universityResult.data.data.getAllUniversities;
      } catch (err) {
        alert(err);
      }
      this.pageLoading = false;
    },

    async getFoodsAndDrinks(loungeId) {
      this.loungeId = loungeId;
      this.foodLoading = true;
      try {
        var fooAndDrinkResult = await axios({
          method: "POST",
          url: this.$store.state.deliveryApi,
          data: {
            query: `{
                 getFoodsAndDrinks(lounge_id:${loungeId}){
                  food_and_drink_id
                  lounge_id
                  food_and_drink_name
                  food_and_drink_image
                   food_and_drink_price
                  food_and_drink_description
                  foil_price    
                }             
                      }
            `,
          },
        });

        this.foodsAndDrinks = fooAndDrinkResult.data.data.getFoodsAndDrinks;
      } catch (err) {
        alert(err);
      }
      this.foodLoading = false;
    },

    async addFoodAndDrink() {
      if (
        this.foodOrDrinkName != "" &&
        this.price != "" &&
        this.description != "" &&
        this.files.length > 0
      ) {
        this.addLoading = true;
        try {
          await this.uploadImage();
          this.blurhash1 = await this.decodeImagetoBlurhash(this.image1);
        } catch (error) {
          console.log(error);
          
        }

        this.image1 = this.imageUrl1;

        try {
          var adderResult = await axios({
            method: "POST",
            url: this.$store.state.deliveryApi,
            data: {
              query: `mutation{
               addFoodAndDrink(
                 lounge_id: ${this.loungeId},
                 food_and_drink_name: "${this.foodOrDrinkName}",
                  food_and_drink_image: "${this.image1}",
                 blurhash: "${this.blurhash1}", 
                 food_and_drink_price: ${parseFloat(this.price)},
                 food_and_drink_description: "${this.description}",
                  foil_price:${this.foilPrice}){
                        statusMessage
                    }

              }
              `,
            },
          });

          const response = adderResult.data.data.addFoodAndDrink;
          if (response[0].statusMessage) {
            alert("Added successfully!");
            this.foodAdderPopUp = false;
            this.files=[]
            this.imageUrl1=""
            this.image1=""
          }
        } catch (err) {
          alert(err);
        }
        this.addLoading = false;
      } else alert("Please fill all required fields");
    },

       async addUniversity() {
      if (
        this.universityName != "" &&
        this.campus != "" &&
        this.coordinate != "" &&
        this.files.length > 0
      ) {
        this.addLoading = true;
        try {
          await this.uploadImage();
          this.blurhash1 = await this.decodeImagetoBlurhash(this.image1);
        } catch (error) {
          console.log(error);
        }

        this.image1 = this.imageUrl1;

        try {
          var universityAdderResult = await axios({
            method: "POST",
            url: this.$store.state.deliveryApi,
            data: {
              query: `mutation{
               addUniversity(               
                 university_name: "${this.universityName}",
                 campus: "${this.campus}",
                 blurhash: "${this.blurhash1}", 
                 university_coordinate: "${this.coordinate}",
                 logo: "${this.image1}"){
                        statusMessage
                    }

              }
              `,
            },
          });

          const response = universityAdderResult.data.data.addUniversity;
          if (response[0].statusMessage) {
            alert("Added successfully!");
            this.addUniversityPopUp = false;
            this.files=[]
            this.imageUrl1=""
            this.image1=""
          }
        } catch (err) {
          alert(err);
        }
        this.addLoading = false;
      } else alert("Please fill all required fields");
    },


       async addLounge() {
      if (
        this.loungeName != "" &&
        this.loungePhone != "" &&
        this.durationInMinutes != "" &&
        this.files.length > 0
      ) {
        this.addLoading = true;
        try {
          await this.uploadImage();
          this.blurhash1 = await this.decodeImagetoBlurhash(this.image1);
        } catch (error) {
          console.log(error);
          
        }

        this.image1 = this.imageUrl1;

        try {
          var adderResult = await axios({
            method: "POST",
            url: this.$store.state.deliveryApi,
            data: {
              query: `mutation{
               addLounge(
                 university_id: ${this.universityID},
                 lounge_name: "${this.loungeName}",
                 lounge_phone: "${this.loungePhone}",
                 lounge_image: "${this.image1}",
                 blurhash: "${this.blurhash1}", 
                 category: "${this.category}",
                 minutes: "${this.durationInMinutes}"){
                        statusMessage
                    }

              }
              `,
            },
          });

          const response = adderResult.data.data.addLounge;
          if (response[0].statusMessage) {
            alert("Added successfully!");
            this.loungeAdderPopUp = false;
            this.files=[]
            this.imageUrl1=""
            this.image1=""
          }
        } catch (err) {
          alert(err);
        }
        this.addLoading = false;
      } else alert("Please fill all required fields");
    },
  },

  async created() {
    this.getUniversity();
  },
};
</script>

<style scoped>
#content {
  border: 1px solid grey;
}
</style>