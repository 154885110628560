<template>
  <div class="container">
    <div class="text-center mt-16" v-if="pageLoading">
      <v-progress-circular
        :size="70"
        :width="2"
        color="grey"
        indeterminate
      ></v-progress-circular>
    </div>
    <div id="home" class="white mt-3 mr-1" v-if="!pageLoading">
      <v-layout row wrap align-content-space-between class="ma-1 pt-4">
        <v-flex xs12 md9>
          <v-layout row wrap class="ma-2">
            <v-flex class="pa-2" xs6 md3 v-if="whichIsClicked == 'new'">
              <v-btn
                @click="getOrder('new')"
                small
                dark
                text
                class="teal darken-3 pl-10 pr-10"
              >
                <span class="text-capitalize">New</span>
              </v-btn>
            </v-flex>

            <v-flex class="pa-2" xs6 md3 v-if="whichIsClicked != 'new'">
              <v-btn
                @click="getOrder('new')"
                small
                text
                class="grey lighten-4 pl-10 pr-10"
              >
                <span class="text-capitalize">New</span>
              </v-btn>
            </v-flex>

            <v-flex class="pa-2" xs6 md3 v-if="whichIsClicked == 'assigned'">
              <v-btn
                @click="getOrder('assigned')"
                small
                dark
                text
                class="teal darken-3 pl-10 pr-10"
              >
                <span class="text-capitalize">Assigned</span>
              </v-btn>
            </v-flex>

            <v-flex class="pa-2" xs6 md3 v-if="whichIsClicked != 'assigned'">
              <v-btn
                @click="getOrder('assigned')"
                small
                text
                class="grey lighten-4 pl-10 pr-10"
              >
                <span class="text-capitalize">Assigned</span>
              </v-btn>
            </v-flex>

            <v-flex class="pa-2" xs6 md3 v-if="whichIsClicked == 'ongoing'">
              <v-btn
                @click="getOrder('ongoing')"
                small
                dark
                text
                class="teal darken-3 pl-10 pr-10"
              >
                <span class="text-capitalize">Ongoing</span>
              </v-btn>
            </v-flex>

            <v-flex class="pa-2" xs6 md3 v-if="whichIsClicked != 'ongoing'">
              <v-btn
                @click="getOrder('ongoing')"
                small
                text
                class="grey lighten-4 pl-10 pr-10"
              >
                <span class="text-capitalize">Ongoing</span>
              </v-btn>
            </v-flex>

            <v-flex class="pa-2" xs6 md3 v-if="whichIsClicked == 'completed'">
              <v-btn
                @click="getOrder('completed')"
                small
                dark
                text
                class="teal darken-3 pl-10 pr-10"
              >
                <span class="text-capitalize">Completed</span>
              </v-btn>
            </v-flex>

            <v-flex class="pa-2" xs6 md3 v-if="whichIsClicked != 'completed'">
              <v-btn
                @click="getOrder('completed')"
                small
                text
                class="grey lighten-4 pl-10 pr-10"
              >
                <span class="text-capitalize">Completed</span>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex xs12 md3 class="text-center" v-if="whichIsClicked == 'new'">
          <v-btn small dark text class="orange darken-4 pl-10 pr-10">
            <span class="text-capitalize">{{ newOrders.length }} New</span>
          </v-btn>
        </v-flex>

        <v-flex xs12 md3 class="text-center" v-if="whichIsClicked == 'ongoing'">
          <v-btn small dark text class="orange darken-4 pl-10 pr-10">
            <span class="text-capitalize"
              >{{ ongoingOrders.length }} Ongoing</span
            >
          </v-btn>
        </v-flex>
        <v-flex
          xs12
          md3
          class="text-center"
          v-if="whichIsClicked == 'completed'"
        >
          <v-btn small dark text class="orange darken-4 pl-10 pr-10">
            <span class="text-capitalize"
              >{{ completedOrders.length }} Completed</span
            >
          </v-btn>
        </v-flex>

        <v-flex
          xs12
          md3
          class="text-center"
          v-if="whichIsClicked == 'assigned'"
        >
          <v-btn small dark text class="orange darken-4 pl-10 pr-10">
            <span class="text-capitalize"
              >{{ assignedOrders.length }} Assigned</span
            >
          </v-btn>
        </v-flex>
      </v-layout>

      <v-layout row wrap class="ml-2 pa-5 pl-10 hidden-sm-and-down">
        <v-flex xs12>
          <br />
        </v-flex>

        <v-flex xs6 md3>
          <h5 class="black--text">Order Info</h5>
        </v-flex>

        <v-flex xs6 md3>
          <h5 class="black--text">User Info</h5>
        </v-flex>
        <v-flex xs6 md3>
          <h5 class="black--text">Loung Info</h5>
        </v-flex>
        <v-flex xs6 md3>
          <h5 class="black--text">Driver Info</h5>
        </v-flex>
      </v-layout>
      <div v-if="whichIsClicked == 'new'" class="pt-5">
        <v-layout
          row
          wrap
          class="pa-2 pt-0 grey--text text--darken-2"
          v-for="order in newOrders"
          :key="order.order_id"
        >
          <v-flex xs12 class="">
            <v-divider class="mb-3"></v-divider>
          </v-flex>

          <v-flex xs7 md3 class="pa-1">
            <img
              height="100px"
              contain
              :src="order.getFoodOrDrink[0].food_and_drink_image"
            />
            <br />
            <span class="black--text" style="font-size: 16px"
              >Order Name:
              {{ order.getFoodOrDrink[0].food_and_drink_name }}</span
            >
            <br />
            <span class="" style="font-size: 13px"
              >Quantity: {{ order.quantity }}</span
            >
            <br />
            <span class="" style="font-size: 13px"
              >Total Fee: {{ order.total_fee }}</span
            >
            <br />
            <span class="" style="font-size: 13px"
              >Order #: {{ order.order_number }}</span
            >
            <br />

            <span class="" style="font-size: 13px"
              >Order Date:
              {{ new Date(Number(order.order_date)) | formatDate }}</span
            >
            <br />
          </v-flex>

          <v-flex xs5 md3 class="pa-1">
            <span class="text-capitalize" style="font-size: 13px"
              >User Name: {{ order.getUser[0].user_first_name }}
              {{ order.getUser[0].user_last_name }}</span
            >
            <br />

            <span class="" style="font-size: 13px"
              >Phone Number: {{ order.getUser[0].phone_number }}
            </span>
            <br />

            <span class="" style="font-size: 13px"
              >Address: {{ order.address }}
            </span>
            <br />

            <span class="" style="font-size: 13px"
              >University:
              {{ order.getUser[0].getUniversity[0].university_name }} ( Compus:
              {{ order.getUser[0].getUniversity[0].campus }})
            </span>
            <br />
          </v-flex>
          <v-flex xs7 md3 class="pa-1">
            <span class="text-capitalize" style="font-size: 13px"
              >Lounge Name:
              {{ order.getFoodOrDrink[0].getLoung[0].lounge_name }}
            </span>
            <br />

            <span class="text-capitalize" style="font-size: 13px"
              >Lounge Phone:
              {{ order.getFoodOrDrink[0].getLoung[0].lounge_phone }}
            </span>
            <br />

            <span class="" style="font-size: 13px"
              >University:
              {{ order.getUser[0].getUniversity[0].university_name }} ( Compus:
              {{ order.getUser[0].getUniversity[0].campus }})
            </span>
            <br />
          </v-flex>
          <v-flex xs5 md2 class="pa-1">
            <span
              class="text-capitalize"
              style="font-size: 13px"
              v-if="order.getAssignedDriver.length > 0"
              >Driver Name:
              {{ order.getAssignedDriver[0].getDriver[0].driver_first_name }}
              &nbsp;
              {{ order.getAssignedDriver[0].getDriver[0].driver_last_name }}
            </span>
            <br v-if="order.getAssignedDriver.length > 0" />

            <span
              class="text-capitalize"
              style="font-size: 13px"
              v-if="order.getAssignedDriver.length > 0"
              >Driver Phone:
              {{ order.getAssignedDriver[0].getDriver[0].driver_phone }}
            </span>

            <br v-if="order.getAssignedDriver.length > 0" />
            <span
              class=""
              style="font-size: 13px"
              v-if="order.getAssignedDriver.length > 0"
              >Ass Date:
              {{
                new Date(Number(order.getAssignedDriver[0].assigned_job_date))
                  | formatDate
              }}</span
            >
            <br v-if="order.getAssignedDriver.length > 0" />
          </v-flex>

          <v-flex xs6 md1 class="pa-1 text-right">
            <v-btn
              @click="getDriverToAssing(order)"
              dark
              x-small
              text
              class="orange darken-4"
            >
              <span class="text-capitalize">Assign</span>
            </v-btn>
          </v-flex>
        </v-layout>

        <v-dialog v-model="dialog" persistent max-width="600px">
          <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Dialog
        </v-btn>
      </template> -->
          <v-card>
            <v-card-title>
              <span class="">Available drivers</span>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                <span class="text-capitalize"> Close</span>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <p class="text-center red--text" v-if="relatedOrders.length > 1">
                {{ relatedOrders.length - 1 }} related order(s)
              </p>
              <v-layout
                class="mt-5"
                row
                wrap
                v-for="driver in orderToAssigneWithItsRoot"
                :key="driver.driver_id"
              >
                <v-flex xs3>
                  <span class="">{{ driver.driver_first_name }}</span>
                </v-flex>

                <v-flex xs3>
                  <span class="">{{ driver.driver_last_name }}</span>
                </v-flex>

                <v-flex xs3>
                  <span class="">{{ driver.driver_phone }}</span>
                </v-flex>

                <v-flex xs2>
                  <v-btn
                    v-if="driver.driver_id != driverId && !assignLoading"
                    @click="assignDriver(driver)"
                    dark
                    x-small
                    text
                    class="orange darken-4"
                  >
                    <span class="text-capitalize">Assign driver</span>
                  </v-btn>
                  <v-progress-circular
                    v-if="driver.driver_id == driverId && assignLoading"
                    :size="30"
                    :width="1"
                    color="grey"
                    indeterminate
                  ></v-progress-circular>
                </v-flex>

                <v-flex xs12 class="mb-3 mt-3">
                  <v-divider></v-divider>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>

      <div v-if="whichIsClicked == 'assigned'" class="pt-5">
        <v-layout
          row
          wrap
          class="pa-2 pt-0 grey--text text--darken-2"
          v-for="order in assignedOrders"
          :key="order.order_id"
        >
          <v-flex xs12 class="">
            <v-divider class="mb-3"></v-divider>
          </v-flex>

          <v-flex xs7 md3 class="pa-1">
            <img
              height="100px"
              contain
              :src="order.getFoodOrDrink[0].food_and_drink_image"
            />
            <br />
            <span class="black--text" style="font-size: 16px"
              >Order Name:
              {{ order.getFoodOrDrink[0].food_and_drink_name }}</span
            >
            <br />
            <span class="" style="font-size: 13px"
              >Quantity: {{ order.quantity }}</span
            >
            <br />
            <span class="" style="font-size: 13px"
              >Total Fee: {{ order.total_fee }}</span
            >
            <br />
            <span class="" style="font-size: 13px"
              >Order #: {{ order.order_number }}</span
            >
            <br />

            <span class="" style="font-size: 13px"
              >Order Date:
              {{ new Date(Number(order.order_date)) | formatDate }}</span
            >
            <br />
          </v-flex>

          <v-flex xs5 md3 class="pa-1">
            <span class="text-capitalize" style="font-size: 13px"
              >User Name: {{ order.getUser[0].user_first_name }}
              {{ order.getUser[0].user_last_name }}</span
            >
            <br />

            <span class="" style="font-size: 13px"
              >Phone Number: {{ order.getUser[0].phone_number }}
            </span>
            <br />

            <span class="" style="font-size: 13px"
              >Address: {{ order.address }}
            </span>
            <br />

            <span class="" style="font-size: 13px"
              >University:
              {{ order.getUser[0].getUniversity[0].university_name }} ( Compus:
              {{ order.getUser[0].getUniversity[0].campus }})
            </span>
            <br />
          </v-flex>
          <v-flex xs7 md3 class="pa-1">
            <span class="text-capitalize" style="font-size: 13px"
              >Lounge Name:
              {{ order.getFoodOrDrink[0].getLoung[0].lounge_name }}
            </span>
            <br />

            <span class="text-capitalize" style="font-size: 13px"
              >Lounge Phone:
              {{ order.getFoodOrDrink[0].getLoung[0].lounge_phone }}
            </span>
            <br />

            <span class="" style="font-size: 13px"
              >University:
              {{ order.getUser[0].getUniversity[0].university_name }} ( Compus:
              {{ order.getUser[0].getUniversity[0].campus }})
            </span>
            <br />
          </v-flex>
          <v-flex xs5 md2 class="pa-1">
            <span
              class="text-capitalize"
              style="font-size: 13px"
              v-if="order.getAssignedDriver.length > 0"
              >Driver Name:
              {{ order.getAssignedDriver[0].getDriver[0].driver_first_name }}
              &nbsp;
              {{ order.getAssignedDriver[0].getDriver[0].driver_last_name }}
            </span>
            <br v-if="order.getAssignedDriver.length > 0" />

            <span
              class="text-capitalize"
              style="font-size: 13px"
              v-if="order.getAssignedDriver.length > 0"
              >Driver Phone:
              {{ order.getAssignedDriver[0].getDriver[0].driver_phone }}
            </span>

            <br v-if="order.getAssignedDriver.length > 0" />
            <span
              class=""
              style="font-size: 13px"
              v-if="order.getAssignedDriver.length > 0"
              >Ass Date:
              {{
                new Date(Number(order.getAssignedDriver[0].assigned_job_date))
                  | formatDate
              }}</span
            >
            <br v-if="order.getAssignedDriver.length > 0" />
          </v-flex>

          <v-flex xs6 md1 class="pa-1 text-right">
            <v-btn
              @click="rejectJob(order.order_id)"
              dark
              x-small
              text
              class="orange darken-4"
            >
              <span class="text-capitalize">Reject</span>
            </v-btn>
          </v-flex>
        </v-layout>

        <!-- <v-dialog v-model="dialog" persistent max-width="600px">
      
          <v-card>
            <v-card-title>
              <span class="">Available drivers</span>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                <span class="text-capitalize"> Close</span>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <p class="text-center red--text" v-if="relatedOrders.length > 1">
                {{ relatedOrders.length - 1 }} related order(s)
              </p>
              <v-layout
                class="mt-5"
                row
                wrap
                v-for="driver in orderToAssigneWithItsRoot"
                :key="driver.driver_id"
              >
                <v-flex xs3>
                  <span class="">{{ driver.driver_first_name }}</span>
                </v-flex>

                <v-flex xs3>
                  <span class="">{{ driver.driver_last_name }}</span>
                </v-flex>

                <v-flex xs3>
                  <span class="">{{ driver.driver_phone }}</span>
                </v-flex>

                <v-flex xs2>
                  <v-btn
                    v-if="driver.driver_id != driverId && !assignLoading"
                    @click="assignDriver(driver.driver_id)"
                    dark
                    x-small
                    text
                    class="orange darken-4"
                  >
                    <span class="text-capitalize">Assign driver</span>
                  </v-btn>
                  <v-progress-circular
                    v-if="driver.driver_id == driverId && assignLoading"
                    :size="30"
                    :width="1"
                    color="grey"
                    indeterminate
                  ></v-progress-circular>
                </v-flex>

                <v-flex xs12 class="mb-3 mt-3">
                  <v-divider></v-divider>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-dialog> -->
      </div>

      <div v-if="whichIsClicked == 'ongoing'" class="pt-5">
        <v-layout
          row
          wrap
          class="pa-2 pt-0 grey--text text--darken-2"
          v-for="order in ongoingOrders"
          :key="order.order_id"
        >
          <v-flex xs12 class="">
            <v-divider class="mb-3"></v-divider>
          </v-flex>

          <v-flex xs7 md3 class="pa-1">
            <img
              height="100px"
              contain
              :src="order.getFoodOrDrink[0].food_and_drink_image"
            />
            <br />
            <span class="black--text" style="font-size: 16px"
              >Order Name:
              {{ order.getFoodOrDrink[0].food_and_drink_name }}</span
            >
            <br />
            <span class="" style="font-size: 13px"
              >Quantity: {{ order.quantity }}</span
            >
            <br />
            <span class="" style="font-size: 13px"
              >Total Fee: {{ order.total_fee }}</span
            >
            <br />
            <span class="" style="font-size: 13px"
              >Order #: {{ order.order_number }}</span
            >
            <br />

            <span class="" style="font-size: 13px"
              >Order Date:
              {{ new Date(Number(order.order_date)) | formatDate }}</span
            >
            <br />
          </v-flex>

          <v-flex xs5 md3 class="pa-1">
            <span class="text-capitalize" style="font-size: 13px"
              >User Name: {{ order.getUser[0].user_first_name }}
              {{ order.getUser[0].user_last_name }}</span
            >
            <br />

            <span class="" style="font-size: 13px"
              >Phone Number: {{ order.getUser[0].phone_number }}
            </span>
            <br />

            <span class="" style="font-size: 13px"
              >Address: {{ order.address }}
            </span>
            <br />

            <span class="" style="font-size: 13px"
              >University:
              {{ order.getUser[0].getUniversity[0].university_name }} ( Compus:
              {{ order.getUser[0].getUniversity[0].campus }})
            </span>
            <br />
          </v-flex>
          <v-flex xs7 md3 class="pa-1">
            <span class="text-capitalize" style="font-size: 13px"
              >Lounge Name:
              {{ order.getFoodOrDrink[0].getLoung[0].lounge_name }}
            </span>
            <br />

            <span class="text-capitalize" style="font-size: 13px"
              >Lounge Phone:
              {{ order.getFoodOrDrink[0].getLoung[0].lounge_phone }}
            </span>
            <br />

            <span class="" style="font-size: 13px"
              >University:
              {{ order.getUser[0].getUniversity[0].university_name }} ( Compus:
              {{ order.getUser[0].getUniversity[0].campus }})
            </span>
            <br />
          </v-flex>
          <v-flex xs5 md2 class="pa-1">
            <span
              class="text-capitalize"
              style="font-size: 13px"
              v-if="order.getAssignedDriver.length > 0"
              >Driver Name:
              {{ order.getAssignedDriver[0].getDriver[0].driver_first_name }}
              &nbsp;
              {{ order.getAssignedDriver[0].getDriver[0].driver_last_name }}
            </span>
            <br v-if="order.getAssignedDriver.length > 0" />

            <span
              class="text-capitalize"
              style="font-size: 13px"
              v-if="order.getAssignedDriver.length > 0"
              >Driver Phone:
              {{ order.getAssignedDriver[0].getDriver[0].driver_phone }}
            </span>

            <br v-if="order.getAssignedDriver.length > 0" />
            <span
              class=""
              style="font-size: 13px"
              v-if="order.getAssignedDriver.length > 0"
              >Ass Date:
              {{
                new Date(Number(order.getAssignedDriver[0].assigned_job_date))
                  | formatDate
              }}</span
            >
            <br v-if="order.getAssignedDriver.length > 0" />
          </v-flex>

          <!-- <v-flex xs6 md1 class="pa-1 text-right">
            <v-btn dark x-small text class="orange darken-4">
              <span class="text-capitalize">Reject</span>
            </v-btn>
          </v-flex> -->
        </v-layout>

        <!-- <v-dialog v-model="dialog" persistent max-width="600px">
      
          <v-card>
            <v-card-title>
              <span class="">Available drivers</span>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                <span class="text-capitalize"> Close</span>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <p class="text-center red--text" v-if="relatedOrders.length > 1">
                {{ relatedOrders.length - 1 }} related order(s)
              </p>
              <v-layout
                class="mt-5"
                row
                wrap
                v-for="driver in orderToAssigneWithItsRoot"
                :key="driver.driver_id"
              >
                <v-flex xs3>
                  <span class="">{{ driver.driver_first_name }}</span>
                </v-flex>

                <v-flex xs3>
                  <span class="">{{ driver.driver_last_name }}</span>
                </v-flex>

                <v-flex xs3>
                  <span class="">{{ driver.driver_phone }}</span>
                </v-flex>

                <v-flex xs2>
                  <v-btn
                    v-if="driver.driver_id != driverId && !assignLoading"
                    @click="assignDriver(driver.driver_id)"
                    dark
                    x-small
                    text
                    class="orange darken-4"
                  >
                    <span class="text-capitalize">Assign driver</span>
                  </v-btn>
                  <v-progress-circular
                    v-if="driver.driver_id == driverId && assignLoading"
                    :size="30"
                    :width="1"
                    color="grey"
                    indeterminate
                  ></v-progress-circular>
                </v-flex>

                <v-flex xs12 class="mb-3 mt-3">
                  <v-divider></v-divider>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-dialog> -->
      </div>

      <div v-if="whichIsClicked == 'completed'" class="pt-5">
        <v-layout
          row
          wrap
          class="pa-2 pt-0 grey--text text--darken-2"
          v-for="order in completedOrders"
          :key="order.order_id"
        >
          <v-flex xs12 class="">
            <v-divider class="mb-3"></v-divider>
          </v-flex>

          <v-flex xs7 md3 class="pa-1">
            <img
              height="100px"
              contain
              :src="order.getFoodOrDrink[0].food_and_drink_image"
            />
            <br />
            <span class="black--text" style="font-size: 16px"
              >Order Name:
              {{ order.getFoodOrDrink[0].food_and_drink_name }}</span
            >
            <br />
            <span class="" style="font-size: 13px"
              >Quantity: {{ order.quantity }}</span
            >
            <br />
            <span class="" style="font-size: 13px"
              >Total Fee: {{ order.total_fee }}</span
            >
            <br />
            <span class="" style="font-size: 13px"
              >Order #: {{ order.order_number }}</span
            >
            <br />

            <span class="" style="font-size: 13px"
              >Order Date:
              {{ new Date(Number(order.order_date)) | formatDate }}</span
            >
            <br />
          </v-flex>

          <v-flex xs5 md3 class="pa-1">
            <span class="text-capitalize" style="font-size: 13px"
              >User Name: {{ order.getUser[0].user_first_name }}
              {{ order.getUser[0].user_last_name }}</span
            >
            <br />

            <span class="" style="font-size: 13px"
              >Phone Number: {{ order.getUser[0].phone_number }}
            </span>
            <br />

            <span class="" style="font-size: 13px"
              >Address: {{ order.address }}
            </span>
            <br />

            <span class="" style="font-size: 13px"
              >University:
              {{ order.getUser[0].getUniversity[0].university_name }} ( Compus:
              {{ order.getUser[0].getUniversity[0].campus }})
            </span>
            <br />
          </v-flex>
          <v-flex xs7 md3 class="pa-1">
            <span class="text-capitalize" style="font-size: 13px"
              >Lounge Name:
              {{ order.getFoodOrDrink[0].getLoung[0].lounge_name }}
            </span>
            <br />

            <span class="text-capitalize" style="font-size: 13px"
              >Lounge Phone:
              {{ order.getFoodOrDrink[0].getLoung[0].lounge_phone }}
            </span>
            <br />

            <span class="" style="font-size: 13px"
              >University:
              {{ order.getUser[0].getUniversity[0].university_name }} ( Compus:
              {{ order.getUser[0].getUniversity[0].campus }})
            </span>
            <br />
          </v-flex>
          <v-flex xs5 md2 class="pa-1">
            <span
              class="text-capitalize"
              style="font-size: 13px"
              v-if="order.getAssignedDriver.length > 0"
              >Driver Name:
              {{ order.getAssignedDriver[0].getDriver[0].driver_first_name }}
              &nbsp;
              {{ order.getAssignedDriver[0].getDriver[0].driver_last_name }}
            </span>
            <br v-if="order.getAssignedDriver.length > 0" />

            <span
              class="text-capitalize"
              style="font-size: 13px"
              v-if="order.getAssignedDriver.length > 0"
              >Driver Phone:
              {{ order.getAssignedDriver[0].getDriver[0].driver_phone }}
            </span>

            <br v-if="order.getAssignedDriver.length > 0" />
            <span
              class=""
              style="font-size: 13px"
              v-if="order.getAssignedDriver.length > 0"
              >Ass Date:
              {{
                new Date(Number(order.getAssignedDriver[0].assigned_job_date))
                  | formatDate
              }}</span
            >
            <br v-if="order.getAssignedDriver.length > 0" />
          </v-flex>
        </v-layout>

        <!-- <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="">Available drivers</span>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                <span class="text-capitalize"> Close</span>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <p class="text-center red--text" v-if="relatedOrders.length > 1">
                {{ relatedOrders.length - 1 }} related order(s)
              </p>
              <v-layout
                class="mt-5"
                row
                wrap
                v-for="driver in orderToAssigneWithItsRoot"
                :key="driver.driver_id"
              >
                <v-flex xs3>
                  <span class="">{{ driver.driver_first_name }}</span>
                </v-flex>

                <v-flex xs3>
                  <span class="">{{ driver.driver_last_name }}</span>
                </v-flex>

                <v-flex xs3>
                  <span class="">{{ driver.driver_phone }}</span>
                </v-flex>

                <v-flex xs2>
                  <v-btn
                    v-if="driver.driver_id != driverId && !assignLoading"
                    @click="assignDriver(driver.driver_id)"
                    dark
                    x-small
                    text
                    class="orange darken-4"
                  >
                    <span class="text-capitalize">Assign driver</span>
                  </v-btn>
                  <v-progress-circular
                    v-if="driver.driver_id == driverId && assignLoading"
                    :size="30"
                    :width="1"
                    color="grey"
                    indeterminate
                  ></v-progress-circular>
                </v-flex>

                <v-flex xs12 class="mb-3 mt-3">
                  <v-divider></v-divider>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-dialog> -->
      </div>
    </div>

    <!-- <div class="mt-16 hidden-md-and-up">
      <p>This systen is not supported in small screens</p>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      whichIsClicked: "new",
      pageLoading: false,
      allOrders: [],
      newOrders: [],
      assignedOrders: [],
      ongoingOrders: [],
      completedOrders: [],
      orderToAssigneWithItsRoot: [],
      orderToAssigne: "",
      relatedOrders: [],
      dialog: false,
      driverId: "",
      assignLoading: false,
      userToken:""
    };
  },
  methods: {
    getOrder(wichIsClicked) {
      this.whichIsClicked = wichIsClicked;
    },
    getDriverToAssing(orderToAssign) {
      this.orderToAssigne = orderToAssign;
      this.relatedOrders = [];
      for (let i = 0; i < this.newOrders.length; i++) {
        if (orderToAssign.order_number == this.newOrders[i].order_number) {
          this.relatedOrders.push(this.newOrders[i].order_id);
        }
      }
      this.orderToAssigneWithItsRoot =
        this.orderToAssigne.getUser[0].getDriverForAssign;

        this.userToken=this.orderToAssigne.getUser[0].device_token

      
      this.dialog = true;
    },

    async getOrders() {
      this.pageLoading = true;
      try {
        var orderResult = await axios({
          method: "POST",
          url: this.$store.state.deliveryApi,
          data: {
            query: `{

               getAllOrders{
    order_id
    user_id
    food_or_drink_id
    address
    payment_method
    total_fee
    order_status
    order_number
    order_date
    quantity
    
    getFoodOrDrink{
      food_and_drink_id
      lounge_id
      food_and_drink_name
      food_and_drink_name
      food_and_drink_image
      food_and_drink_price
      food_and_drink_description
      quantity
      foil_price      
      
      getLoung{
        lounge_id
        university_id
        lounge_name
        lounge_phone
        lounge_image
        category
        minutes
        averagePrice
        
      }
    }
    
    getUser{
      user_id
      university_id
      user_first_name
      user_last_name
      phone_number
      device_token
      
      getUniversity{
        university_id
        university_name
        university_coordinate
        campus
        logo
        
      }
      
      getDriverForAssign{
        driver_id
        university_id
        driver_first_name
        driver_last_name
        device_token
        driver_phone
        driver_address
        
      }
      
    }
    
    getAssignedDriver{
      assigned_job_id
      delivery_order_id
      driver_id
      assigned_job_date
      getDriver{
        driver_id
        university_id
        driver_first_name
        driver_last_name
        device_token
        driver_phone
        driver_address
        
      }
    }
  }

                      }
            `,
          },
        });

        this.allOrders = orderResult.data.data.getAllOrders;
        this.newOrders = [];
        this.assignedOrders = [];
        this.ongoingOrders = [];
        this.completedOrders = [];
        for (let i = 0; i < this.allOrders.length; i++) {
          if (this.allOrders[i].order_status == "OP")
            this.newOrders.push(this.allOrders[i]);
          else if (this.allOrders[i].order_status == "AS")
            this.assignedOrders.push(this.allOrders[i]);
          else if (this.allOrders[i].order_status == "ACC")
            this.ongoingOrders.push(this.allOrders[i]);
          else if (this.allOrders[i].order_status == "AR")
            this.completedOrders.push(this.allOrders[i]);
        }
      } catch (err) {
        alert(err);
      }
      this.pageLoading = false;
    },

    async assignDriver(driver_id) {     
      
      const toAccept = confirm("Are you sure you want to assign this driver?");
      if (toAccept) {
        this.driverId = driver_id.driver_id;

      
        this.assignLoading = true;
        try {
          var result = await axios({
            method: "POST",
            url: this.$store.state.deliveryApi,
            data: {
              query: `mutation{
                          assignJob(order_number : "${this.orderToAssigne.order_number}",delivery_order_id : "${this.relatedOrders.toString()}", driver_id : ${parseInt(driver_id.driver_id)}){
                          statusMessage
                              }
                        }
                `,
            },
          });

          var response = result.data.data.assignJob;
          if (response[0].statusMessage) {
               await this.sendPushNotification("You have new job, please deliver it ","New order",driver_id.device_token)

            this.dialog = false;
            this.getOrders();
          }
        } catch (err) {
          alert(err);
        }
        this.assignLoading = false;
        this.driverId = "";
      }
    },

    async rejectJob(order_id) {
      const toReject = confirm("Are you sure you want to reject this order?");
      if (toReject) {
        this.assignLoading = true;
        try {
          var result = await axios({
            method: "POST",
            url: this.$store.state.deliveryApi,
            data: {
              query: `mutation{
                          rejectJob(delivery_order_id : ${order_id}){
                          statusMessage
                              }
                        }
                `,
            },
          });

          var response = result.data.data.rejectJob;
          if (response[0].statusMessage) {
            this.dialog = false;
            this.getOrders();
          }
        } catch (err) {
          alert(err);
        }
        this.assignLoading = false;
      }
    },
    async sendPushNotification(body,title,to){

        var data = JSON.stringify({
  "notification": {
    "body": body ,
    "title": title
  },
  "priority": "high",
  "data": {
    "click_action": "FLUTTER_NOTIFICATION_CLICK",
    "id": "1",
    "status": "done",
    // "ride_request_id": "-MxU-r1Ne2Oxvh9LVVGH"
  },
  "to": to
});

    var config = {
  method: 'post',
  url: 'https://fcm.googleapis.com/fcm/send',
  headers: { 
    'Content-Type': 'application/json', 
    'Authorization': 'key=AAAAahcY6Gk:APA91bF0SrhjOOmkLfWecVCEWbw8uJGUk5hzTBU3mkT9XLLgLxWiU79Xu8mzGU-otNEZbqOyYihFr620-PK1VG5CBP3aE3BOoHim2u2BHadTXlyAd6TJ-AX0nY9lh1DKFpI-xcuhxUo5'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});

    }
  },
  created() {
    this.getOrders();
  
  },
};
</script>
<style scoped>
#home {
  height: 90vh;
  border-radius: 3px;
}
</style>

<style scoped>
img {
  border-radius: 5px;
}
</style>