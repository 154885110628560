<template>
  <v-container>
    <div v-if="$store.state.isLoggedin" class="mt-10">
      <div class="text-center" v-if="pageLoading">
        <v-progress-circular
          :size="70"
          :width="2"
          color="grey"
          indeterminate
        ></v-progress-circular>
      </div>
      <v-layout
        row
        wrap
        justify-space-around
        class="text-center"
        id="dashboard"
        v-if="!pageLoading"
      >
        <v-flex xs12 md3>
          <a
            @click="
              $router.push({
                name: 'orders',
              })
            "
          >
            <v-card outlined class="ma-2 pt-10 pb-10">
              <span style="font-size: 24px" class="red--text">{{
                orderCounts
              }}</span
              ><br />
              <span style="font-size: 13px" class="grey--text">
                Total Orders</span
              >
            </v-card>
          </a>
        </v-flex>
        <v-flex xs12 md3>
          <a
            @click="
              $router.push({
                name: 'user',
                params: {
                  users: newUsers,
                  userType: 'new users',
                },
              })
            "
          >
            <v-card outlined class="ma-2 pt-10 pb-10">
              <span style="font-size: 24px" class="orange--text">{{
                earningCounts * deliveryFee
              }}</span
              ><br />
              <span style="font-size: 13px" class="grey--text"
                >Total Earning</span
              >
            </v-card>
          </a>
        </v-flex>
        <v-flex xs12 md3>
          <a>
            <v-card outlined class="ma-2 pt-10 pb-10">
              <span style="font-size: 24px" class="blue--text"
                >{{ userCounts }} </span
              ><br />
              <span style="font-size: 13px" class="grey--text">
                Total Users</span
              >
            </v-card>
          </a>
        </v-flex>
        <v-flex xs12 md3>
          <a
            @click="
              $router.push({
                name: 'user',
                params: {
                  users: totalUsers,
                  userType: 'total users',
                },
              })
            "
          >
            <v-card outlined class="ma-2 pt-10 pb-10">
              <span style="font-size: 24px" class="green--text">{{
                driverCounts
              }}</span
              ><br />
              <span style="font-size: 13px" class="grey--text"
                >Total Drivers</span
              >
            </v-card>
          </a>
        </v-flex>

        <v-flex xs12 md3>
          <a
         @click="
              $router.push({
                name: 'university',
              })
            "
          >
            <v-card outlined class="ma-2 pt-10 pb-10">
              <span style="font-size: 24px" class="pink--text">{{
                universityCounts
              }}</span
              ><br />
              <span style="font-size: 13px" class="grey--text"
                >Total Universities</span
              >
            </v-card>
          </a>
        </v-flex>
      </v-layout>
    </div>

    <div v-else id="error" class="text-center mt-16">
      <p class="red--text text-center">Please login first</p>
      <a @click="$router.push({ name: 'login' })">login</a>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    pageLoading: false,
    orderCounts: 0,
    earningCounts: 0,
    userCounts: 0,
    driverCounts: 0,
    universityCounts: 0,
    deliveryFee: 5,
  }),
  methods: {
    async getCounts() {
      this.pageLoading = true;

      try {
        var orderCountResult = await axios({
          method: "POST",
          url: this.$store.state.deliveryApi,

          data: {
            query: `{
                countOrders{
                  count_result
                }
              }
              `,
          },
        });

        this.orderCounts =
          orderCountResult.data.data.countOrders[0].count_result;
      } catch (err) {
        alert(err);
      }

      try {
        var earningCountResult = await axios({
          method: "POST",
          url: this.$store.state.deliveryApi,

          data: {
            query: `{
                countEarnings{
                  count_result
                }
              }
              `,
          },
        });

        this.countEarnings =
          earningCountResult.data.data.countEarnings[0].count_result;
      } catch (err) {
        alert(err);
      }

      try {
        var userCountResult = await axios({
          method: "POST",
          url: this.$store.state.deliveryApi,

          data: {
            query: `{
                countUsers{
                  count_result
                }
              }
              `,
          },
        });

        this.userCounts = userCountResult.data.data.countUsers[0].count_result;
      } catch (err) {
        alert(err);
      }

      try {
        var driverCountResult = await axios({
          method: "POST",
          url: this.$store.state.deliveryApi,

          data: {
            query: `{
                countDrivers{
                  count_result
                }
              }
              `,
          },
        });

        this.driverCounts =
          driverCountResult.data.data.countDrivers[0].count_result;
      } catch (err) {
        alert(err);
      }

      try {
        var universityCountResult = await axios({
          method: "POST",
          url: this.$store.state.deliveryApi,

          data: {
            query: `{
                countUniversities{
                  count_result
                }
              }
              `,
          },
        });

        this.universityCounts =
          universityCountResult.data.data.countUniversities[0].count_result;
      } catch (err) {
        alert(err);
      }

      this.pageLoading = false;
    },
  },

  created() {
    this.getCounts();
  },
};
</script>
<style scoped>
#error {
  max-width: 300px;
  margin: auto;
}

#dashboard {
  max-width: 800px;
  margin: auto;
}
</style>