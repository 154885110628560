import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    deliveryApi: "https://delivery.abimabusiness.com/graphql",
    isLoggedin: false,

  },
  mutations: {
    loginAdmin(state, isLoggedin) {
      state.isLoggedin = isLoggedin;
    },
  },
  actions: {
    loginAdmin(context, isLoggedin) {
      context.commit("loginAdmin", isLoggedin);
    },
  },

  plugins: [createPersistedState()],
  modules: {
  }
})
