import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueBlurHash from "vue-blurhash";
import firebase from "firebase/app";


import moment from "moment";



Vue.use(VueBlurHash);

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(new Date(value)).format("YYYY-MM-DD hh:mm:ss");
  }
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  created() {
    firebase.initializeApp({
      apiKey: "AIzaSyCtnsW9g1VMsKeF0fmVOLICPSZhyjRg3Qs",
      authDomain: "abima-delivery.firebaseapp.com",
      projectId: "abima-delivery",
      storageBucket: "abima-delivery.appspot.com",
      messagingSenderId: "455654041705",
      appId: "1:455654041705:web:a38daa4b9bc295ba9c5af8",
      measurementId: "G-4Y4DX8PCVM"
    });
},
}).$mount('#app')
