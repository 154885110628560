var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.$store.state.isLoggedin)?_c('div',{staticClass:"mt-10"},[(_vm.pageLoading)?_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"size":70,"width":2,"color":"grey","indeterminate":""}})],1):_vm._e(),(!_vm.pageLoading)?_c('v-layout',{staticClass:"text-center",attrs:{"row":"","wrap":"","justify-space-around":"","id":"dashboard"}},[_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('a',{on:{"click":function($event){return _vm.$router.push({
              name: 'orders',
            })}}},[_c('v-card',{staticClass:"ma-2 pt-10 pb-10",attrs:{"outlined":""}},[_c('span',{staticClass:"red--text",staticStyle:{"font-size":"24px"}},[_vm._v(_vm._s(_vm.orderCounts))]),_c('br'),_c('span',{staticClass:"grey--text",staticStyle:{"font-size":"13px"}},[_vm._v(" Total Orders")])])],1)]),_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('a',{on:{"click":function($event){return _vm.$router.push({
              name: 'user',
              params: {
                users: _vm.newUsers,
                userType: 'new users',
              },
            })}}},[_c('v-card',{staticClass:"ma-2 pt-10 pb-10",attrs:{"outlined":""}},[_c('span',{staticClass:"orange--text",staticStyle:{"font-size":"24px"}},[_vm._v(_vm._s(_vm.earningCounts * _vm.deliveryFee))]),_c('br'),_c('span',{staticClass:"grey--text",staticStyle:{"font-size":"13px"}},[_vm._v("Total Earning")])])],1)]),_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('a',[_c('v-card',{staticClass:"ma-2 pt-10 pb-10",attrs:{"outlined":""}},[_c('span',{staticClass:"blue--text",staticStyle:{"font-size":"24px"}},[_vm._v(_vm._s(_vm.userCounts)+" ")]),_c('br'),_c('span',{staticClass:"grey--text",staticStyle:{"font-size":"13px"}},[_vm._v(" Total Users")])])],1)]),_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('a',{on:{"click":function($event){return _vm.$router.push({
              name: 'user',
              params: {
                users: _vm.totalUsers,
                userType: 'total users',
              },
            })}}},[_c('v-card',{staticClass:"ma-2 pt-10 pb-10",attrs:{"outlined":""}},[_c('span',{staticClass:"green--text",staticStyle:{"font-size":"24px"}},[_vm._v(_vm._s(_vm.driverCounts))]),_c('br'),_c('span',{staticClass:"grey--text",staticStyle:{"font-size":"13px"}},[_vm._v("Total Drivers")])])],1)]),_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('a',{on:{"click":function($event){return _vm.$router.push({
              name: 'university',
            })}}},[_c('v-card',{staticClass:"ma-2 pt-10 pb-10",attrs:{"outlined":""}},[_c('span',{staticClass:"pink--text",staticStyle:{"font-size":"24px"}},[_vm._v(_vm._s(_vm.universityCounts))]),_c('br'),_c('span',{staticClass:"grey--text",staticStyle:{"font-size":"13px"}},[_vm._v("Total Universities")])])],1)])],1):_vm._e()],1):_c('div',{staticClass:"text-center mt-16",attrs:{"id":"error"}},[_c('p',{staticClass:"red--text text-center"},[_vm._v("Please login first")]),_c('a',{on:{"click":function($event){return _vm.$router.push({ name: 'login' })}}},[_vm._v("login")])])])}
var staticRenderFns = []

export { render, staticRenderFns }