<template>
  <v-container>
    <v-row class="text-center" id="login">
      <v-col cols="12" class="mt-16">
        <p class="headline">Log in</p>
      </v-col>
      <v-flex xs12 class="mt-1">
        <v-text-field
          style="font-size: 11px"
          v-model="username"
          :rules="[(v) => !!v || 'Required']"
          label="User name"
          required
          dense
          outlined
        ></v-text-field>
      </v-flex>

      <v-flex xs12>
        <v-text-field
          style="font-size: 11px"
          v-model="password"
          :rules="[(v) => !!v || 'Required']"
          label="Password"
          required
          dense
          outlined
          type="password"
        ></v-text-field>
      </v-flex>

      <v-flex xs12>
        <v-btn dark small text class="orange darken-4" @click="login">
          <span class="text-capitalize">Login</span>
        </v-btn>
      </v-flex>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    username: "",
    password: "",
  }),

  methods: {
    login() {
      if (this.password == "abima@321" && this.username == "abima") {
        this.$store.dispatch("loginAdmin", true);
        this.$router.push({ name: "home" });
      } else alert("Please enter correct username and password");
    },
  },
  created() {
    this.$store.dispatch("loginAdmin", false);
  },
};
</script>
<style scoped>
#login {
  max-width: 300px;
  margin: auto;
}
</style>